/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';
import qs from 'qs';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */

/**
 * @param sectionContent.sectionContent
 * @param {object} sectionContent - Data for section
 * @returns {ReactElement} GoogleMap component
 */
const GoogleMap = ({ sectionContent, ...props }) => {
  const updateLink = url => {
    let finalUrl = url;
    if (url?.indexOf('/embed?') < 0) {
      if (url.indexOf('https://www.google.com/maps?') > -1) {
        let parsedUrl = qs
          .parse(url, {
            charset: 'utf-8',
            charsetSentinel: true,
          })
          ?.['https://www.google.com/maps?ll']?.split(',');
        // consoleLog('coords', parsedUrl, parsedUrl['https://www.google.com/maps?ll']);
        if (parsedUrl?.length) {
          finalUrl = `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20000!2d${parsedUrl[1]}!3d${parsedUrl[0]}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098`;
        }
      } else if (url.indexOf('https://www.google.com/maps/') > -1) {
        let cordinates = url
          ?.split('/')
        [url.split('/').length - 1]?.replace('@', '')
          ?.split(',');
        if (cordinates) {
          finalUrl = `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20000!2d${cordinates[1]}!3d${cordinates[0]}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098`;
        }
      }
    }
    // let coords = /\@([0-9\.\,\-a-zA-Z]*)/.exec(url);
    // https://www.google.com/maps?ll=11.706322,78.090733&z=14&t=m&hl=en&gl=IN&mapclient=embed&cid=12369432778851422870
    // consoleLog('coords', coords, url);
    // url = url.replace('/maps?', '/maps/embed?');

    // if (coords) {
    //   let coordsArray = coords[1].split(',');
    //   url = `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d20000!2d${coordsArray[1]}!3d${coordsArray[0]}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2suk!4v1486486434098`;
    // }
    return finalUrl;
  };

  return (
    <MidContainer w={'768'}>
      <Box
        as="iframe"
        width={{ base: '93%', md: '100%' }}
        maxW={'640px'}
        // height="480px"
        height={{
          base: props.ht || '480px',
          md: props.ht || '480px',
        }}
        allowFullScreen
        frameBorder="0"
        loading="lazy"
        src={updateLink(sectionContent?.url)}
        m="0 auto"
        my="15px"
      />
    </MidContainer>
  );
};

export default GoogleMap;
